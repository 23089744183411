@import (reference) '../../styles/colors.less';

.more-menu {
	cursor: pointer;

	&-trigger {
		padding: 2px 5px;
	}

	&-content {
		background: @brandPrimary;
		box-shadow: 0 0 6px 0 rgba(139, 139, 139, 0.1);
		border-radius: 8px;
		font-weight: 600;
		font-size: 14px;
		color: #ffffff;
		overflow: hidden;
		min-width: 100px;

		&-item {
			padding: 5px 20px;

			&:hover {
				background-color: @brandPrimaryHover;
			}
		}
	}
}
