@whiteOverlayBackground: rgba(255, 255, 255, 0.8);
@whiteModalContentTransition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
@whiteModalMinContentHeight: 160px;
@modalContentPadding: 20px;

.z-index-buffer-1 {
	z-index: 1;
}

.z-index-buffer-2 {
	z-index: 2;
}

.z-index-buffer-3 {
	z-index: 3;
}

.z-index-buffer-4 {
	z-index: 4;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;

	&.modal-active {
		z-index: 5;

		/* Order matters... IE11 needs block */
		display: block;
		display: initial;
	}

	&-overlay-no-padding {
		& > .modal-overlay-content {
			width: 80vw;
			padding: 0;
		}
	}

	&-overlay {
		background: transparent;
		width: 100%;
		height: 100%;
		transition: 0.3s;
		overflow-y: auto;
		display: flex;
		justify-content: center;
		align-items: center;

		&-no-background {
			background: transparent;
		}

		&-after-open {
			transition: 0.3s;
			background: @whiteOverlayBackground;
		}

		&-before-close {
			transition: 0.3s;
			background: transparent;
		}

		&-content {
			background: #ffffff;
			border: 1px solid #bababa;
			border-radius: 8px;
			box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
			box-sizing: border-box;
			max-width: 900px;
			min-height: @whiteModalMinContentHeight;
			min-width: 360px;
			opacity: 0;
			padding: @modalContentPadding;
			transform: translate(0, -50%);
			transition: @whiteModalContentTransition;

			&:focus {
				outline: none;
			}

			&-after-open {
				transform: none;
				transition: @whiteModalContentTransition;
				opacity: 1;
			}

			&-before-close {
				transform: translate(0, -50%);
				transition: @whiteModalContentTransition;
				opacity: 0;
			}

			&-header {
				text-align: right;
			}
		}
	}

	.modal-overlay.plugin-modal {
		align-items: stretch;
	}

	.modal-overlay-content.plugin-modal {
		border: 0px;
		box-shadow: unset;
		box-sizing: unset;
		margin: 0px;
		min-width: unset;
		padding: 0px;
		transition: none;
	}

	.plugin-modal {
		.modal-overlay-content-after-open {
			flex-grow: 1;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}
