@import '../../styles/colors.less';

.settings-sectionHeader {
	font-size: 18px;
	color: @titles;
	margin-top: 20px;
	margin-bottom: 20px;
}

.settings-admin-company-container, .settings-admin-meetings-container, .settings-admin-users-container {
	margin-top: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #f0eded;
}

.settings-sectionSubHeaderGray {
	width: 500px;
	font-size: 14px;
	color: #858585;
	margin-top: 10px;
	text-align: left;
}

.settings-radioButtonContainer  {
	font-size: 14px;
	margin: 20px 0 0 0;
}

.settings-radioButton {
	align-items: center;
	color: @titles;
	cursor: pointer;
	display: flex;
	font-size: 14px;
	margin-bottom: 10px;
}

.settings-admin-users-innerContainer {
	display: flex;
	gap: 20px;
	width: 500px;
	height: 200px;
	font-size: 14px;
	margin: 15px 0 10px 0;
	padding: 0 0 0 50px;
	background: #FFFFFF;
	border: 1px solid @inputBorderColor;
	box-shadow: 0 0 6px 0 rgba(139,139,139,0.10);
	border-radius: 3px;
}

.settings-admin-users-billing {
	padding-bottom: 15px;
}

.settings-admin-users-infoContainer {
	margin-top: 36px; 
	margin-right: 30px;
}

.settings-admin-users-licenseCount {
	font-size: 14px;
	color: #858585;
	margin-bottom: 20px;
}

.settings-admin-users-licenseCount strong {
	font-weight: normal;
	color: @titles;
}

.settings-admin-users-contactSupport {
	font-size: 14px;
	color: #858585;
	margin-bottom: 20px;
}

.settings-contactSupportLink {
	color: @brandPrimary;
	cursor: pointer;
}

.settings-manageUsersLink {
	cursor: pointer;
}

.settings-admin-users-manage {
	font-size: 16px;
	color: @brandPrimary;
}

.settings-inviteEmployees-container {
	background-color: white;
	min-height: 395px;
	width: 600px;

	&-header {
		text-align: right;
	}
}

.settings-ReferAFriend-container {
	background-color: white;
	height: 600px;
	width: 490px;
}

.settings-inviteEmployees-graphic {
	display: flex;
	justify-content: center;
}

.settings-inviteEmployeees-headline {
	text-align: center;
	font-size: 16px;
	color: @brandSecondary;
	margin-bottom: 15px;
}

.settings-inviteEmployees-subHeadline {
	text-align: center;
	font-size: 14px;
	color: #767676;
	margin-bottom: 25px;
}

.settings-inviteEmployees-errorContainer {
	font-size: 14px;
	color: #B34755;
	margin-bottom: 10px;
}

.settings-inviteEmployees-formLabel {
	display: inline-block;
	font-size: 14px;
	color: #767676;
	margin-right: 10px;
}

.settings-inviteEmployees-linkContainer {
	height: 100px;
	border-top: 1px solid #D3DBDE;
	padding: 20px;
	color: @titles;
	font-size: 14px;
	text-align: center;
}

.settings-inviteEmployees-buttonContainer {
	margin-top: 30px;
	text-align: center;
}

.settings-inviteEmployees-confirmationContainer {
	margin: 0 30px 0 30px;
}

.settings-deactive-text {
}

.settings-admin-loader {
	margin:50px auto 0px auto;
}
